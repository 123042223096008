import React from "react";
import "../../styles/gst.css";
import Testimonial from "../../components/Testimonial";
import FAQAccord from "../../components/GFlush";
import Layout from "../../components/Layout";
import { Seo } from "../../components/seo";
import Counter from "../../components/Counter";
import Guidance from "../../components/GSTTemp/Guidance";
import Cta from "../../components/Cta";
// import Hero from "../../components/Hero";
import ScrollNav from "../../components/GSTTemp/ScrollNav";
import ImgContent from "../../components/GSTTemp/ImgContent";
import ContentForm from "../../components/GSTTemp/ContentForm";
import ContentImg from "../../components/GSTTemp/ContentImg";
import SliderContent from "../../components/GSTTemp/SliderContent";
import AboutWithForm from "../../components/GSTTemp/AboutWithForm";
import Package from "../../components/GSTTemp/Package";

export default function MsMeRegistration() {
  /* Scroll Nav Data */
  const MsMeScrollId = [
    {
      id: `#procedure`,
      heading: `Procedure`,
    },
    {
      id: `#benefits`,
      heading: `Advantage of MSME`,
    },
    {
      id: `#document`,
      heading: `Documents Required`,
    },
    {
      id: `#features`,
      heading: `Features`,
    },
  ];

  /* Package Data */
  const PackageCompData = {
    header: `MSME Registration`,
    buyBtnLink: `#pricing-buy`,
    price: `4999`,
    f_price: `6999`,
    content: [
      {
        points: `PAN & TAN Registration `,
        icon: '/imgs/icons/gst.png',
        alt_tag: 'PAN & TAN Registration',
        background: '',
        plus: '+',
      },
      {
        points: `PF + ESIC + Professional Tax`,
        icon: '/imgs/icons/itr_filing.png',
        alt_tag: 'TODAYFILINGS ITR E-filing service',
        background: 'bg-f78acb',
        plus: '+',
        plus_dnone: 'd-none d-md-block',
      },
      {
        points: `MSME Registration`,
        icon: '/imgs/icons/enterprises.png',
        alt_tag: 'MSME Registration',
        background: 'bg-eb6b3d',
        plus: '+',
      },
      {
        points: `Tax Consultancy Service `,
        icon: '/imgs/icons/consultant.png',
        alt_tag: 'Tax Consultancy Service',
        background: 'bg-c679e3',
      },
    ],
  };
  /* Img Content Component Data */
  const MsMeIcData = {
    id: 'procedure',
    mt_div: 'mt-5',
    mt_img: 'mt-5',
    header: 'Procedure For MSME Registration',
    image: '/imgs/registration/msme/msme-slider.png',
    alt_tag: 'MsMe Registration Procedure',
    Paragraph: `The applicants must first complete out the registration
    application form for MSMEs with all the relevant
    information, after which they must submit the registration
    application to the appropriate department. You have the
    option of starting the application form filing process
    offline or online.`,
    points: [
      {
        content: `Bring together and gather all the documentation called for
        in the MSME Registration Application form. To go to the next step of
        registration, gathering all the required documents often takes one to two
        working days.`,
        icon: true,
      },
      {
        content: `The applicant
        must submit the application form to the Registrar of udyog
        aadhar msme along with the requisite papers. The processing
        and verification of the legitimacy or authenticity of the
        applicant's documents are done by the regulatory
        authorities. An additional two working days are needed for
        this verification and processing.`,
        icon: true,
      },
      {
        content: `The applicant's
        company is registered as an MSME if the regulatory
        authorities are satisfied with the documentation and other
        submissions made by the applicant.`,
        icon: true,
      },
    ],
  };

  /* Content Form Component Data */
  const MsMeCfData = {
    id: 'benefits',
    mt_div: 'mt-5',
    mt_img: 'mt-5',
    header: 'Advantages of MSME Registration',
    content: `The advantages of MSME Registration Certificate Online
          in India are as follows:`,
    body: [
      {
        points: `When compared to the interest on regular loans, the
        rate of interest on loans is significantly lower.`,
        icon: true,
      },
      {
        points: `Once someone registers for a new udyog aadhar in
        India, the interest rate on bank loans given to
        these types of businesses drops dramatically to
        between 1 and 1.5 percent.`,
        icon: true,
      },
      {
        points: `The credit for MAT (Minimum Alternate Tax), which is
        also available to registered MSMEs, can be carried
        forward for about 15 years rather than the previous
        10 years.`,
        icon: true,
      },
      {
        points: `There are a number of government contracts that are
        solely available to MSME businesses.`,
        icon: true,
      },
      {
        points: `The MSME industries are permitted to have simple
        access to credit and financing.`,
        icon: true,
      },
      {
        points: `Businesses that are recognized as MSMEs will be
        given priority when applying for government licenses
        and certifications.`,
        icon: true,
      },
      {
        points: `For any unpaid MSME certificate amounts, a one-time
        settlement fee is levied.`,
        icon: true,
      },
    ],
  };

  /*  Content Img Component Data */
  const MsMeCiData = {
    id: 'document',
    background: 'main-content',
    mt_div: 'mt-3',
    header: 'Documents Required For MSME Registration',
    paragraph: `The following list contains the paperwork and data needed for
    MSME registration in India:`,
    points: [
      {
        content: `Aadhar Card of the applicant.`,
        icon: true,
      },
      {
        content: `The social category of the applicant- Gender and Physical
        status of the applicant- physically disabled or not.`,
        icon: true,
      },
      {
        content: `Name and type of enterprise.`,
        icon: true,
      },
      {
        content: `PAN Number,NIC Number and the official Address of the enterprise’s plant.`,
        icon: true,
      },
      {
        content: `Date of incorporation of the business.`,
        icon: true,
      },
      {
        content: `Details of the
        previous registration and bank details of the enterprise.`,
        icon: true,
      },
      {
        content: `The business
        operations of the enterprise- "Service." or "Manufacturing.`,
        icon: true,
      },
      {
        content: `Details related
        to Employment such as total number of worker or employees
        working in the industry or organization.`,
        icon: true,
      },
      {
        content: `The information
        on investment in machinery and equipment unit of the
        organization.`,
        icon: true,
      },
    ],
    image: '/imgs/registration/msme/msme-img.png',
    alt_tag: 'Required Documents for MsMe Registration',
  };

  /* ImgSlider Data */
  const MsMeImages = [
    '/imgs/registration/msme/msme.png',
    '/imgs/registration/msme/msme-reg.png',
    '/imgs/registration/msme/ms-me.png',
  ];

  /*  Slider Content Component Data */
  const MsMeSCData = {
    id: 'features',
    background: [],
    mt_div: 'mt-3',
    header: 'Features Includes in MSME Registration',
    content: [
      {
        points: `ISO
        Certification Charges Reimbursement`,
        icon: true,
      },
      {
        points: `Subsidy on Patent Registration`,
        icon: true,
      },
      {
        points: `Overdraft Interest Rate Exemption`,
        icon: true,
      },
      {
        points: `Industrial Promotion Subsidy Eligibility`,
        icon: true,
      },
      {
        points: `Fewer
        Electricity Bills`,
        icon: true,
      },
    ],
    images: MsMeImages,
  };

  /* About Data With Form*/
  const MsMeAboutData = {
    header: `Online MSME Registration Service`,
    sub_title: `For Personal and Business Related MSME Registration Service`,
    content_paragraph: [
      `By filling out an online application for Udyog aadhar MSME registration,
      small businesses can qualify for preferential tax deductions, sector lending,
      capital investments, and power tariff grants. In India's socioeconomic development,
      MSME is a significant factor. Aadhaar registration form—the sole official
      document needed—must be filled out with an Aadhaar Card.`,

      // `According to the MSMED, or Micro, Small, and Medium
      // Enterprises Development Act of 2006, MSMEs are divided into
      // two categories and are as follows: Manufacturing businesses
      // engaged in the production or manufacture of goods for the
      // market. Service oriented businesses that supply or render
      // services.`,
    ],
  };

  // /* Slider */
  // const MsMeSlider = [
  //   {
  //     header_color: false,
  //     content_color: false,
  //     background: `linear-gradient(139.4deg, rgb(198, 243, 242) 28.54%, rgb(181, 201, 253) 104.43%)`,
  //     header: `MSME Registration Service`,
  //     content: `India's Best MSME Registration Service`,
  //     image: "/imgs/registration/msme/msme-doc.png",
  //     alt_tag: "Best Online MSME Registration Service Near Bangalore",
  //   },
  //   {
  //     header_color: false,
  //     content_color: false,
  //     background: `linear-gradient(139.4deg, rgb(252, 237, 216) 28.54%, rgb(245, 197, 198) 104.43%)`,
  //     header: `Online MSME Registration`,
  //     content: `Best MSME Registration Service in Hosur`,
  //     image: "/imgs/registration/msme/msme-slide.png",
  //     alt_tag: "Best Online MSME Registration Service in Hosur",
  //   },
  //   {
  //     header_color: false,
  //     content_color: false,
  //     background: `linear-gradient(139.4deg, rgb(207, 249, 216) 28.54%, rgb(243, 238, 160) 104.43%)`,
  //     header: `Online MSME Registration Service`,
  //     content: `Best MSME Registration Service Platform`,
  //     image: "/imgs/registration/msme/ms-me-reg.png",
  //     alt_tag: "Best Online MSME Registration Service in Hosur",
  //   },
  // ];

  /* FaQ Data */
  var MSME_FAQ = [
    {
      header: 'What is the MSME Form-1?',
      body: [
        {
          content: `Generally speaking, you can access GST Return Filing using a variety
          of methods, including an OTP from your enrolled telephone
          number/PAN/DSC.`,
          icon: false,
        },
      ],
    },
    {
      header: 'How to find lost Udyog Aadhaar number?',
      body: [
        {
          content: ` The procedure is not hard. The user can directly visit the official website and apply for the UAM
          which is Udyog Aadhaar Memorandum. This document contains the Udyog Aadhaar number. In case the Udyog Aadhaar
           number is lost, the user needs to know other details and then enter them after they click on forgot my Udyog
            Aadhaar number. The procedure is similar to how people find their lost Aadhaar number when they lose it.`,
          icon: false,
        },
      ],
    },
    // 2
    {
      header: 'In which cities we offer MSME Registration services?',
      body: [
        {
          content: `All around India, we provide services for MSME registration. For a
          number of clients, we have previously successfully registered
          several MSMEs in cities like Chennai, Delhi, Mumbai, Bangalore,
          Kolkata, Gujarat, and Uttar Pradesh.`,
          icon: false,
        },
      ],
    },
    {
      header: 'Is Aadhar card compulsory for MSME Registration?',
      body: [
        {
          content: `Yes. Aadhar card is a prerequisite for the MSME Registration process
          under the Udyam Aadhar initiative. The Aadhar cards of the director
          and partner are necessary for registration if the applicant is not
          the proprietor.`,
          icon: false,
        },
      ],
    },
    {
      header: ' What is the validity of Udyog Aadhaar certificate?',
      body: [
        {
          content: `The Udyog Aadhaar certificates are valid until the enterprise that is registered under it is
          functioning. There are sometimes changes that have to be updated when the owner dies. Udyog certificates
           otherwise never expire. The start and end both with the enterprise.`,
          icon: false,
        },
      ],
    },
    // 3
    {
      header: 'What is Udyam Aadhar in India?',
      body: [
        {
          content: `The government issues Udyam Aadhar, a 12-digit identity number, to
          corporate enterprises.`,
          icon: false,
        },
      ],
    },
    {
      header: 'How to update Udyog Aadhar?',
      body: [
        {
          content: `The users can log in to their udyog Aadhaar online and can do udyog Aadhaar update.
          The process of updating is easy, the users just need to remember all the credentials like their
          Udyog Aadhaar number and the registered mobile number. After that Udyog Aadhaar edit is possible.
           After updating, Udyog Aadhaar download is recommended.`,
          icon: false,
        },
      ],
    },
    {
      header: 'What are the Uses of Udyog Aadhar certificate?',
      body: [
        {
          content: ` The certificate provides the companies registered under Udyog aadhaar special access to the schemes by govt.
          It directly links the enterprises to the govt. database and through it, they will get benefits faster and easily.`,
          icon: false,
        },
      ],
    },
    // 4
    {
      header: 'Whether the credit score is mandatory for MSMEs?',
      body: [
        {
          content: `No, the credit score is not needed or necessary for MSMEs.`,
          icon: false,
        },
      ],
    },
    {
      header: 'Is Udyog Aadhaar and SSI registration same?',
      body: [
        {
          content: `Both of them are different but the enterprises registered under SSI can register for Udyog Aadhaar.
           Udyog Aadhaar is the identity of all the MSMEs in India. There are many benefits of Udyog Aadhaar.`,
          icon: false,
        },
      ],
    },
  ];
  return (
    <div>
      <Seo
        title='MSME Registration'
        keywords='Keywords'
        description='Description'
        name='TODAYFILINGS'
        type='Website'
        author='TODAYFILINGS'
      />

      <Layout>
        <main id='main'>
          {/* <Hero sliders={MsMeSlider} /> */}
          <Package packageData={PackageCompData} />

          <AboutWithForm about_content={MsMeAboutData} />

          <Counter />

          <ScrollNav scroll_data={MsMeScrollId} />

          <ImgContent item={MsMeIcData} />

          <ContentForm CFSection_data={MsMeCfData} />
          {/* MsMe Certificate */}
          <section id='certificates' className=''>
            <div className='container'>
              <div className='row'>
                <div className='col-lg-1 col-md-1'></div>
                <div className='col-lg-10 col-md-10 col-sm-12 content '>
                  <div className='desc ipr'>
                    {/* ------------------MSME Certifiactes------------------ */}

                    <header className='section-header'>
                      <h2>
                        MSME registration certificate
                        <div className='bar'></div>
                      </h2>
                    </header>
                    <p className='desc'>
                      An entity must first register under MSME if it wants to
                      use the MSME registration certificate. The process or
                      processes that the entity should adhere to in order to
                      obtain such registration are as follows.
                    </p>
                    <p className='desc'>
                      <i className='bi bi-check-circle-fill'></i>
                      Registration of new business owners, that is, those who
                      have an EM-II (Entrepreneurship Memorandum - II) or who
                      are still awaiting MSME registration.
                    </p>
                    <p className='desc'>
                      <i className='bi bi-check-circle-fill'></i>
                      Registration of UAM holders, including those who obtained
                      their UAM through assisted filing.
                    </p>
                    <div className='row'>
                      <div className='col-md-6'>
                        <h5>Entrepreneurship Memorandum–II</h5>
                        <p className='desc'>
                          <i className='bi bi-check-circle-fill'></i>
                          The applicants who fall into this category must select
                          the link marked "For New Enterprises who are not
                          Registered as MSME or those with EM-II" on the
                          website's front page.
                        </p>
                        <p className='desc'>
                          <i className='bi bi-check-circle-fill'></i>
                          This will take you to a page where you can fill out a
                          form asking for information about your Aadhar number
                          and your name as a company owner or entrepreneur.
                        </p>
                      </div>
                      <div className='col-md-6'>
                        <h5>Udyog Aadhar Memorandum</h5>
                        <p className='desc'>
                          <i className='bi bi-check-circle-fill'></i>
                          On the website's front page, candidates who fit this
                          description should click "For those having
                          registration as UAM" or "For those already having
                          registration as UAM through filing."
                        </p>
                        <p className='desc'>
                          <i className='bi bi-check-circle-fill'></i>
                          Currently, the applicant has the option of receiving
                          the OTP for validation on either the email or mobile
                          number provided in the UAM.
                        </p>
                      </div>
                    </div>
                    <div className='container mb-5'>
                      <header className='section-header'>
                        <h2>
                          MSME Registration Fees
                          <div className='bar'></div>
                        </h2>
                      </header>
                      <p className='desc'>
                        The organization you choose to complete your
                        registration through will determine the cost of your
                        MSME registration. There is no fee for MSME registration
                        while using the government website. However, the
                        registration process with the government is arduous and
                        challenging. You'll have a lot of difficulties. Your
                        forms will need to be filled out twice. Therefore, a
                        very minimal fee for MSME registration will be assessed
                        when you work with a company like ours that employs
                        knowledgeable advisers to complete your registration.
                      </p>
                    </div>
                  </div>
                </div>
                <div className='col-lg-1 col-md-1'></div>
              </div>
            </div>
          </section>

          <ContentImg CISection_data={MsMeCiData} />

          <SliderContent ScSection_data={MsMeSCData} />
          <Cta />

          <Guidance />

          <FAQAccord items={MSME_FAQ} />

          <Testimonial />
        </main>
      </Layout>
    </div>
  );
}
